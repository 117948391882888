import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  return (<Layout>
    <SEO title="Request Info - Confirmation" keywords={[]}/>
    <div className='container'>
      <div className="grid">
        <div className="grid__column">
          <h1 className='title title--xxxl color-primary'>
            Request Info: Confirmation
          </h1>
        </div>
      </div>
      <div className="grid">
        <div className="grid__column grid__column--12 grid__column--4--md">
          <Img
            className='margin-sm margin-y'
            fluid={data.iBrochure.childImageSharp.fluid}
            style={{maxWidth: '268px'}}
          />
        </div>
        <div className="grid__column grid__column--12 grid__column--8--md">
          <p style={{marginTop: '2rem'}}>Your request has been received. Thank you for your interest in
          Infinity Structures products. We will get back to you shortly.</p>
        </div>
      </div>
    </div>
  </Layout>);
}


export const pageQuery = graphql`
    query {
        iBrochure: file(relativePath: { eq: "request-info/brochure.png" }) {
            childImageSharp {
                fluid(maxWidth: 268) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
    }
`

